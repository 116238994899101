import "@/styles/globals.css";
import "@/styles/mdx.css";
import { type Session } from "next-auth";
import { SessionProvider } from "next-auth/react";
import { type AppProps } from "next/app";
import { Inter } from "next/font/google";
import localFont from "next/font/local";
import { ThemeProvider } from "@/components/theme-provider";
import { Analytics } from "@/components/analytics";
import { Toaster } from "@/components/ui/toaster";
import { TailwindIndicator } from "@/components/tailwind-indicator";
import { api } from "~/utils/api";
import { KnowledgeProvider } from "~/context/knowledge-content";
import { ProjectProvider } from "~/context/project-content";
import { BillingProvider } from "~/context/billing-content";
import { SpeedInsights } from "~/components/speed-insights";
import { SentryUserManager } from "~/components/sentry-user-manager";
import { useRouter } from "next/router";
import { ChatProvider } from "~/context/chat-content";

const fontSans = Inter({
  subsets: ["latin"],
  variable: "--font-sans",
});

const fontHeading = localFont({
  src: "../assets/fonts/CalSans-SemiBold.woff2",
  variable: "--font-heading",
});

const MyApp = ({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps<{ session: Session | null }>) => {
  const router = useRouter();

  if (session?.user) {
    router.push("/projects").catch(console.error);
  }

  return (
    <SessionProvider session={session}>
      <Analytics />
      <SpeedInsights />
      <ThemeProvider attribute="class" defaultTheme="system" enableSystem>
        {/* Ensure the body class names and font variables are correctly applied */}
        <div
          className={`min-h-screen bg-background antialiased ${fontSans.variable} ${fontHeading.variable}`}
        >
          <BillingProvider>
            <ChatProvider>
              <KnowledgeProvider>
                <ProjectProvider>
                  <Component {...pageProps} />
                  <Analytics />
                  <Toaster />
                  <TailwindIndicator />
                  <SentryUserManager />
                </ProjectProvider>
              </KnowledgeProvider>
            </ChatProvider>
          </BillingProvider>
        </div>
      </ThemeProvider>
    </SessionProvider>
  );
};

export default api.withTRPC(MyApp);
