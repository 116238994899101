// src/context/billing-content.tsx

import React, { createContext, useContext, type ReactNode } from "react";
import { api } from "~/utils/api";
import type { Billing } from "@prisma/client";
import type { QueryObserverResult } from "@tanstack/react-query";
import type Stripe from "stripe";

interface BillingContextType {
  billingInfo: Billing | undefined;
  subscription: Stripe.Subscription | undefined;
  isDataLoading: boolean;
  error: unknown;
  refetch: () => Promise<QueryObserverResult>; // Updated to reflect the promise returned by refetch
}

const BillingContext = createContext<BillingContextType | undefined>(undefined);

export const useBilling = (): BillingContextType => {
  const context = useContext(BillingContext);
  if (!context) {
    throw new Error("useBilling must be used within a BillingProvider");
  }
  return context;
};

export const BillingProvider = ({ children }: { children: ReactNode }) => {
  const { data, isLoading, error, refetch } =
    api.post.getUserBilling.useQuery();
  const { billingFromDb, subscriptionFromStripe } = data ?? {};

  const contextValue: BillingContextType = {
    billingInfo: billingFromDb ?? undefined,
    subscription: subscriptionFromStripe ?? undefined,
    isDataLoading: isLoading,
    error,
    refetch,
  };

  return (
    <BillingContext.Provider value={contextValue}>
      {children}
    </BillingContext.Provider>
  );
};
