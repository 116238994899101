import { useEffect } from "react";
import { setUser } from "@sentry/nextjs";
import { useSession } from "next-auth/react";

export function SentryUserManager() {
  const { data: session } = useSession();

  useEffect(() => {
    if (session?.user?.email) {
      setUser({
        email: session.user.email,
        id: session.user.id,
      });
    } else {
      setUser(null);
    }
  }, [session]);
  return <></>;
}
