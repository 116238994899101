import React, { createContext, useContext, useState, type ReactNode } from "react";
import { api } from "~/utils/api";
import type { ChatSession, Message } from "@prisma/client";
import type { QueryObserverResult } from "@tanstack/react-query";

interface ChatContextType {
  sessionId: number | undefined;
  sessions: (ChatSession & { messages: Message[] })[];
  messages: Message[];
  isLoadingMessages: boolean;
  isDataLoading: boolean;
  error: unknown;
  refetch: () => Promise<QueryObserverResult>;
  refetchMessages: () => Promise<QueryObserverResult>;
  setSessionId: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const ChatContext = createContext<ChatContextType | undefined>(undefined);

export const useChat = (): ChatContextType => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error("useChat must be used within a ChatProvider");
  }
  return context;
};

export const ChatProvider = ({ children }: { children: ReactNode }) => {
  const [sessionId, setSessionId] = useState<number | undefined>(undefined);
  const { data, isLoading, error, refetch } = api.post.fetchChatSessions.useQuery(
    {
      skip: 0,
      take: 10,
    },
    {
      keepPreviousData: true,
      refetchInterval: 5000,
    },
  );
  const { data: messages, isLoading: isLoadingMessages, refetch: refetchMessages } = api.post.fetchMessages.useQuery({ sessionId: sessionId ?? -1 });

  const contextValue: ChatContextType = {
    sessionId: sessionId,
    sessions: data?.sessions ?? [],
    messages: messages?.messages ?? [],
    isLoadingMessages,
    isDataLoading: isLoading,
    error,
    refetch,
    refetchMessages,
    setSessionId,
  };

  return (
    <ChatContext.Provider value={contextValue}>
      {children}
    </ChatContext.Provider>
  );
};
