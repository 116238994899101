import React, { createContext, useContext, type ReactNode } from "react";
import { api } from "~/utils/api";
import type { Project } from "@prisma/client";
import type { QueryObserverResult } from "@tanstack/react-query";

interface ProjectContextType {
  projects: Project[];
  isDataLoading: boolean;
  error: unknown;
  refetch: () => Promise<QueryObserverResult>; // Updated to reflect the promise returned by refetch
  deleteProject: (projectId: string) => Promise<{ success: boolean; message: string }>;
}

const ProjectContext = createContext<ProjectContextType | undefined>(undefined);

export const useProject = (): ProjectContextType => {
  const context = useContext(ProjectContext);
  if (!context) {
    throw new Error("useProject must be used within a ProjectProvider");
  }
  return context;
};

export const ProjectProvider = ({ children }: { children: ReactNode }) => {
  const { data, isLoading, error, refetch } = api.post.fetchProjects.useQuery(
    {
      skip: 0,
      take: 10,
    },
    {
      keepPreviousData: true,
      refetchInterval: 5000,
    },
  );

  const deleteProject = api.post.deleteProject.useMutation();

  const contextValue: ProjectContextType = {
    // Ensure projects is always an array, even if data is undefined
    projects: data?.items ?? [],
    isDataLoading: isLoading,
    error,
    refetch,
    deleteProject: (projectId: string) => deleteProject.mutateAsync({ projectId }),
  };

  return (
    <ProjectContext.Provider value={contextValue}>
      {children}
    </ProjectContext.Provider>
  );
};
