import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function formatDate(input: string | number): string {
  // Check if the input is a number, if so assume it's a Unix timestamp in seconds
  // and multiply by 1000 to convert to milliseconds.
  const timestamp = typeof input === "number" ? input * 1000 : input;
  const date = new Date(timestamp);
  return date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
}
