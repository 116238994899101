import React, { createContext, useContext, type ReactNode } from "react";
import { api } from "~/utils/api";
import type { KnowledgeFile } from "@prisma/client";
import type { QueryObserverResult } from "@tanstack/react-query";

interface KnowledgeContextType {
  knowledgeFiles: KnowledgeFile[];
  isDataLoading: boolean;
  error: unknown;
  refetch: () => Promise<QueryObserverResult>; // Updated to reflect the promise returned by refetch
  deleteKnowledgeFile: (
    knowledgeFileId: string,
  ) => Promise<{ success: boolean; message: string }>;
}

const KnowledgeContext = createContext<KnowledgeContextType | undefined>(
  undefined,
);

export const useKnowledge = (): KnowledgeContextType => {
  const context = useContext(KnowledgeContext);
  if (!context) {
    throw new Error("useKnowledge must be used within a KnowledgeProvider");
  }
  return context;
};

export const KnowledgeProvider = ({ children }: { children: ReactNode }) => {
  const { data, isLoading, error, refetch } = api.post.fetchKnowledge.useQuery(
    {
      skip: 0,
      take: 10,
    },
    {
      keepPreviousData: true,
      refetchInterval: 5000,
    },
  );

  const deleteKnowledgeFile = api.post.deleteKnowledgeFile.useMutation();

  const contextValue: KnowledgeContextType = {
    // Ensure knowledgeFiles is always an array, even if data is undefined
    knowledgeFiles: data?.items ?? [],
    isDataLoading: isLoading,
    error,
    refetch,
    deleteKnowledgeFile: (knowledgeFileId: string) =>
      deleteKnowledgeFile.mutateAsync({ knowledgeFileId }),
  };

  return (
    <KnowledgeContext.Provider value={contextValue}>
      {children}
    </KnowledgeContext.Provider>
  );
};
